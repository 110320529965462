<template>
  <div class="app-container orderCenterList">
    <div class="merch-card scorr-roll">
      <!-- 搜索项 -->
      <el-form ref="queryForm" :model="orderApi" :inline="true">
        <el-form-item>
          <el-input
            clearable
            placeholder="订单号"
            v-model="orderApi.id"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input clearable placeholder="主订单号" v-model="orderApi.groupId">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            clearable
            placeholder="收货人或电话"
            v-model="orderApi.userNameOrPhone"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            clearable
            placeholder="单位名称"
            v-model="orderApi.comName"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            clearable
            placeholder="团队名称"
            v-model="orderApi.teamName"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            clearable
            placeholder="业务员姓名"
            v-model="orderApi.salesmanName"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            clearable
            placeholder="物流公司"
            v-model="orderApi.logistics"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            clearable
            placeholder="物流单号"
            v-model="orderApi.waybill"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            clearable
            placeholder="共享供应商"
            v-model="orderApi.businessName"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            clearable
            placeholder="销售供应商"
            v-model="orderApi.saleBusinessName"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="orderApi.jobStatus"
            clearable
            placeholder="作业状态"
          >
            <el-option
              v-for="item in selData.jobTypeList"
              :key="item.id"
              :label="item.Name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-select
            v-model="orderApi.userTypeId"
            clearable
            placeholder="会员类型"
          >
            <el-option
              v-for="item in userType"
              :key="item.id"
              :label="item.type_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="orderApi.payableMethod"
            clearable
            placeholder="支付方式"
          >
            <el-option
              v-for="item in payOption"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="orderApi.transmit"
            clearable
            placeholder="是否传输"
          >
            <el-option label="未传输" value="0"></el-option>
            <el-option label="已传输" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="orderApi.settleStatus"
            clearable
            placeholder="结清状态"
          >
            <el-option
              v-for="item in selData.settleStatus"
              :key="item.id"
              :label="item.Name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="orderApi.repaymentStatus"
            clearable
            placeholder="还款状态"
          >
            <el-option
              v-for="item in selData.repaymentStatus"
              :key="item.id"
              :label="item.Name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="orderApi.source" clearable placeholder="来源">
            <el-option
              v-for="item in selData.ordersource"
              :key="item.id"
              :label="item.Name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="订单创建时间">
          <el-date-picker
            clearable
            v-model="orderApi.createTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toSearch">查询</el-button>
          <el-button
            @click="eliminate"
            class="gree_btn"
            style="margin-left: 28px"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
      <div class="sign">
        <!-- 左边 -->
        <div class="rightBtn">
          <div
            class="defaultStyle"
            v-for="(item, index) in linst"
            :key="index"
            :class="queryParams.statuValue === item.id ? 'opp' : ''"
            @click="StatusValue(item)"
          >
            {{ item.name }}
          </div>
        </div>
        <!-- 右边 -->
        <div class="leftBtn fx">
          <div class="fx" @click="dialogShipmentoff">
            <img src="@/assets/img/Frame.png" alt="" class="imagesing" />
            <el-button type="text" style="margin: 0px 5px 0px 3px"
              >订单导出</el-button
            >
          </div>
        </div>
      </div>
      <div class="rightds">
        <div>订单总数量：{{ moneyfrom.orderCount }}张</div>
        <div style="margin-left: 20px">
          订单总金额：￥{{ moneyfrom.totalAmount }}
        </div>
      </div>
      <!-- 表格数据 -->
      <div class="dataForm">
        <el-table
          :data="OrderqueryList.list"
          :header-cell-style="{ background: '#F2F3F5', color: '#383B46' }"
          border
          style="width: 100%"
          v-loading="loading"
        >
          <el-table-column
            align="center"
            fixed="left"
            label="序号"
            type="index"
            width="70"
          >
            <template slot-scope="{ row }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="row.index + ''"
                placement="top"
              >
                <div class="exceed">{{ row.index }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="主订单号" width="180" align="center">
            <template slot-scope="{ row }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="row.groupId + ''"
                placement="top"
              >
                <div class="exceed">{{ row.groupId }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="订单编号/创建时间" width="200" align="center">
            <template slot-scope="{ row }">
              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">{{ row.id }}<br />{{ row.createTime }}</div>
                <div>
                  <p class="exceed">{{ row.id }}</p>
                  <p class="exceed">{{ row.createTime }}</p>
                </div>
                <!-- <div class="exceed">12312</div> -->
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="单位名称" width="180" align="center">
            <template slot-scope="{ row }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="row.comName"
                placement="top"
              >
                <div class="exceed">{{ row.comName }}</div>
              </el-tooltip>
              <div
                class="exceed font-size-14 exceed_color"
                @click="CheckId(row)"
                v-if="allJurisdiction.orderCenterList134"
              >
                查看证件
              </div>
            </template>
          </el-table-column>
          <el-table-column label="共享供应商" align="center" width="180">
            <template slot-scope="{ row }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="row.businessName"
                placement="top"
              >
                <div class="exceed">{{ row.businessName }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="销售供应商" width="180" align="center">
            <template slot-scope="{ row }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="row.saleBusinessName"
                placement="top"
              >
                <div class="exceed">{{ row.saleBusinessName }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="会员类型" width="180" align="center">
            <template slot-scope="{ row }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="row.userType"
                placement="top"
              >
                <div class="exceed">{{ row.userType }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="afterSales" label="售后" align="center">
            <template slot-scope="scope">
              <div style="color: #e63e3f" v-if="scope.row.afterSales">
                {{ scope.row.afterSales }}
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column label="收货人/联系电话" width="180" align="center">
            <template slot-scope="{ row }">
              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">
                  {{ row.userConsignee }}<br />{{ row.userPhone }}
                </div>
                <div class="exceed">
                  <p class="exceed">{{ row.userConsignee }}</p>
                  <p class="exceed">{{ row.userPhone }}</p>
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="物流公司/物流单号" width="180" align="center">
            <template slot-scope="{ row }">
              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">
                  {{ row.logistics }}<br />{{ row.waybill }}
                </div>
                <div class="exceed">
                  <p class="exceed">{{ row.logistics }}</p>
                  <p class="exceed">{{ row.waybill }}</p>
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="订单金额 (元)" align="center" width="180">
            <template slot-scope="{ row }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="row.totalAmount + ''"
                placement="top"
              >
                <div class="exceed">{{ row.totalAmount }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="应付金额 (元)" align="center" width="120">
            <template slot-scope="{ row }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="row.payableAmount + ''"
                placement="top"
              >
                <div class="exceed">{{ row.payableAmount }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="paymentMethod"
            label="支付方式"
            align="center"
            width="150"
          >
            <template slot-scope="{ row }">
              <Matching :option="payOption" :mess="row.payableMethod" />
              <div v-if="row.payableMethod == 50">
                <span v-if="row.settleStatus == 0" style="color: #e63e3f"
                  >未结清</span
                >
                <span v-if="row.settleStatus == 1" style="color: #06b7ae"
                  >已结清</span
                >
              </div>
              <!-- <Matching  :option="selData.settleStatus" :mess="row.settleStatus" v-if="row.payableMethod==50"/> -->
            </template>
          </el-table-column>
          <el-table-column prop="paymentMethod" label="订单状态" align="center">
            <template slot-scope="{ row }">
              <el-tooltip
                v-if="row.orderStatus >= 0 && row.orderStatus < 20"
                class="item"
                effect="dark"
                content="待付款"
                placement="top"
              >
                <div class="exceed">待付款</div>
              </el-tooltip>
              <el-tooltip
                v-if="row.orderStatus >= 20 && row.orderStatus < 45"
                class="item"
                effect="dark"
                content="待发货"
                placement="top"
              >
                <div class="exceed">待发货</div>
              </el-tooltip>
              <el-tooltip
                v-if="row.orderStatus >= 45 && row.orderStatus < 60"
                class="item"
                effect="dark"
                content="待收货"
                placement="top"
              >
                <div class="exceed">待收货</div>
              </el-tooltip>
              <el-tooltip
                v-if="row.orderStatus == 60"
                class="item"
                effect="dark"
                content="已收货"
                placement="top"
              >
                <div class="exceed">已收货</div>
              </el-tooltip>
              <el-tooltip
                v-if="row.orderStatus == 70"
                class="item"
                effect="dark"
                content="已完成"
                placement="top"
              >
                <div class="exceed">已完成</div>
              </el-tooltip>
              <el-tooltip
                v-if="row.orderStatus == -2"
                class="item"
                effect="dark"
                content="已取消"
                placement="top"
              >
                <div class="exceed">已取消</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="jobStatusStr" label="作业状态" align="center">
            <template slot-scope="{ row }">
              {{ row.jobStatusStr || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="paymentMethod" label="还款状态" align="center">
            <template slot-scope="{ row }">
              <Matching
                :option="selData.repaymentStatus"
                :mess="row.repaymentStatus"
              />
            </template>
          </el-table-column>
          <el-table-column prop="paymentMethod" label="交易状态" align="center">
            <template slot-scope="{ row }">
              <Matching :option="selData.payment" :mess="row.payment" />
            </template>
          </el-table-column>
          <el-table-column prop="paymentMethod" label="订单来源" align="center">
            <template slot-scope="{ row }">
              <span v-if="row.source == '0'">客户端</span>
              <span v-if="row.source == '1'">清单分享</span>
              <span v-if="row.source == '2'">自动请货</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="paymentMethod"
            label="发票类型"
            align="center"
            width="150"
          >
            <template slot-scope="{ row }">
              <div class="exceed" v-if="row.userBill">
                {{ row.userBill.billType | billType }}
                <el-popover placement="right" width="400" trigger="hover">
                  <div style="line-height: 20px">
                    <div>发票类型：{{ row.userBill.billType | billType }}</div>
                    <div class="fx">
                      <div style="flex: 8">
                        税号：{{ row.userBill.taxId || "未填写" }}
                      </div>
                      <div
                        v-show="
                          row.userBill.taxId != null && row.userBill.taxId != ''
                        "
                        style="flex: 1"
                        class="text-center click_fly"
                        @click="copyed(row.userBill.taxId)"
                      >
                        复制
                      </div>
                    </div>
                    <div class="fx">
                      <div style="flex: 8">
                        开户银行：{{ row.userBill.bankName || "未填写" }}
                      </div>
                      <div
                        v-show="
                          row.userBill.bankName != null &&
                          row.userBill.bankName != ''
                        "
                        style="flex: 1"
                        class="text-center click_fly"
                        @click="copyed(row.userBill.bankName)"
                      >
                        复制
                      </div>
                    </div>
                    <div class="fx">
                      <div style="flex: 8">
                        开户行账号：{{ row.userBill.account || "未填写" }}
                      </div>
                      <div
                        v-show="
                          row.userBill.account != null &&
                          row.userBill.account != ''
                        "
                        style="flex: 1"
                        class="text-center click_fly"
                        @click="copyed(row.userBill.account)"
                      >
                        复制
                      </div>
                    </div>
                    <div class="fx">
                      <div style="flex: 8">
                        公司名称：{{ row.userBill.companyName || "未填写" }}
                      </div>
                      <div
                        v-show="
                          row.userBill.companyName != null &&
                          row.userBill.companyName != ''
                        "
                        style="flex: 1"
                        class="text-center click_fly"
                        @click="copyed(row.userBill.companyName)"
                      >
                        复制
                      </div>
                    </div>
                    <div class="fx">
                      <div style="flex: 8">
                        公司地址：{{ row.userBill.companyAddress || "未填写" }}
                      </div>
                      <div
                        v-show="
                          row.userBill.companyAddress != null &&
                          row.userBill.companyAddress != ''
                        "
                        style="flex: 1"
                        class="text-center click_fly"
                        @click="copyed(row.userBill.companyAddress)"
                      >
                        复制
                      </div>
                    </div>
                    <div class="fx">
                      <div style="flex: 8">
                        联系电话：{{ row.userBill.contactNumber || "未填写" }}
                      </div>
                      <div
                        v-show="
                          row.userBill.contactNumber != null &&
                          row.userBill.contactNumber != ''
                        "
                        style="flex: 1"
                        class="text-center click_fly"
                        @click="copyed(row.userBill.contactNumber)"
                      >
                        复制
                      </div>
                    </div>
                  </div>
                  <div
                    class="lianle"
                    slot="reference"
                    @mouseenter="oninforma(row.userId)"
                    v-show="row.userBill"
                  >
                    开票信息
                  </div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="100"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-button
                type="text"
                size="small"
                @click="detailsing(row)"
                v-if="allJurisdiction.orderCenterList133"
                >详情</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="cancelOrder(row)"
                v-if="row.orderStatus == '0'"
                >取消订单</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="fy">
        <div class="">
          <el-tooltip placement="top">
            <div slot="content">
              <div style="text-align: center">
                <div>实际成交总金额=已付款总金额 - 已退款总金额</div>
                <div>
                  ￥{{ moneyfrom.realTotalAmount }} = ￥{{
                    Number(moneyfrom.realTotalAmount) +
                    Number(moneyfrom.refundAmount)
                  }}
                  - ￥{{ moneyfrom.refundAmount }}
                </div>
              </div>
            </div>
            <span style="margin-left: 20px">
              实际成交总金额：
              <span
                style="
                  color: #ff0000;
                  text-decoration: underline;
                  cursor: pointer;
                "
                class="numsize"
                >￥{{ formatNumberWithCommas(moneyfrom.realTotalAmount) }}</span
              >
            </span>
          </el-tooltip>
          <span style="margin-left: 20px">
            成交订单数：
            <span class="numsize">{{ moneyfrom.realOrderCount }}</span>
          </span>
          <span style="margin-left: 20px">
            成交客户数：
            <span class="numsize">{{ moneyfrom.userCount }}</span>
          </span>
          <span style="margin-left: 20px">
            已退总金额：
            <span style="color: #ff0000" class="numsize"
              >￥{{ formatNumberWithCommas(moneyfrom.refundAmount) }}</span
            >
          </span>
          <span style="margin-left: 20px">
            已出库金额：
            <span style="color: #ff0000" class="numsize"
              >￥{{ formatNumberWithCommas(moneyfrom.outboundAmount) }}</span
            >
          </span>
          <span style="margin-left: 20px">
            待出库总金额：
            <span style="color: #ff0000" class="numsize"
              >￥{{
                formatNumberWithCommas(moneyfrom.awaitOutboundAmount)
              }}</span
            >
          </span>
        </div>
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :page-size="OrderqueryList.per_page"
          layout="total, prev, pager, next, jumper"
          :total="OrderqueryList.total"
          :current-page="orderApi.currPage"
        >
        </el-pagination>
      </div>
      <!-- 批量发货弹窗 -->
      <el-dialog
        title="物流信息填写"
        :visible.sync="dialogShipment"
        width="30%"
      >
        <div>
          确定对当前选中的
          <span class="green">{{ ShipmentList.length }}</span>
          张订单商品已发出？
        </div>
        <div style="margin: 5px 0px 15px 0px">
          发货前，你需要填写物流公司和物流单号：
        </div>
        <div
          v-for="(v, i) in ShipmentList"
          :key="i"
          style="margin-bottom: 20px"
        >
          <fieldset class="shiped">
            <legend class="shiping">
              订单号：<span class="green">{{ v.orderNumber }}</span>
            </legend>
            <div style="margin: 10px"></div>
            物流公司：<el-select
              v-model="v.logisticscom"
              placeholder="请选择"
              style="width: 250px"
            >
              <el-option
                v-for="(item, index) in alltype"
                :key="index"
                :label="item.Name"
                :value="item.id"
              />
            </el-select>
            <div style="margin: 10px"></div>
            物流单号：<el-input
              style="width: 250px"
              v-model="v.Logisticsordin"
              placeholder="请填写"
            ></el-input>
          </fieldset>
        </div>
        <div slot="footer" class="text-right" style="margin-top: -30px">
          <el-button @click="dialogShipment = false">取 消</el-button>
          <el-button @click="AddShipment" class="back_green bai"
            >确 定</el-button
          >
        </div>
      </el-dialog>
      <!-- 弹窗 -->
      <Popupedr ref="popu" :getdataed="getdataed" />
      <!-- 查看证件弹窗 -->
      <CheckseeId ref="seeid" :qualificationData="qualificationData" />
    </div>
    <iframe style="display: none" :src="downUrl" frameborder="0"></iframe>
  </div>
</template>

<script>
import CheckseeId from "./components/CheckseeId.vue";
import Popupedr from "./components/Popupedr.vue";
import { createNamespacedHelpers } from "vuex";
import { downLoadZip } from "@/utils/zipdownload";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
import { verificationTime } from "@/utils/index.js";
const { mapState, mapActions } = createNamespacedHelpers("Order"); //vuex公共库
export default {
  name: "orderCenterList",
  components: {
    CheckseeId,
    Popupedr,
  },
  data() {
    return {
      moneyfrom: {
        totalAmount: 0, //总金额
        orderCount: 0, //订单数量

        realTotalAmount: 0, //实际成交总金额
        realOrderCount: 0, //成交订单数量
        userCount: 0, //成交客户数
        refundAmount: 0, //已退总金额
        outboundAmount: 0, //已出库总金额
        awaitOutboundAmount: 0, //待出库总金额
      },
      payOption: [],
      downUrl: "",
      qualificationData: [],
      searchData: {
        page: 1,
      },
      loading: false,
      // 搜索栏
      queryParams: {
        statuValue: 0, //标记状态值
      },
      // 时间选择配置项
      pickerOptions: {
        shortcuts: [],
      },
      // 点击标记
      linst: [
        { name: "全部", number: 23, id: 0 },
        { name: "待付款", number: 23, id: 1 },
        { name: "待发货", number: 26, id: 2 },
        { name: "待收货", number: 45, id: 3 },
        { name: "已完成", number: 12, id: 70 },
        { name: "已取消", number: 34, id: -2 },
        { name: "已收货", number: 78, id: 60 },
      ],
      total: 5,
      selData: require("../../assets/json/orderSel.json"),
      // 下拉框
      alltype: [
        {
          Name: "选项1",
          id: 1,
        },
      ],
      //日期值
      dateRange: [],
      // 订单查询列表数据
      OrderqueryList: {},
      //选中数据
      ids: [],
      // 批量按钮是否禁用
      batch: true,
      //批量发货（物流信息填写）弹窗
      dialogShipment: false,
      //批量发货（物流信息填写）数据
      ShipmentList: [],
      //弹窗数据
      getdataed: {},
      orderApi: {
        groupId: "",
        id: "",
        userNameOrPhone: "",
        teamName: "",
        comName: "",
        salesmanName: "",
        logistics: "",
        waybill: "",
        businessName: "",
        saleBusinessName: "",
        payableMethod: "",
        transmit: "", //是否传输
        userTypeId: "",
        orderStatus: "",
        settleStatus: "",
        repaymentStatus: "",
        source: "",
        createTimeStart: "",
        createTimeEnd: "",
        currPage: 1,
        createTime: [],
      },

      Billingdata: {},
    };
  },

  filters: {
    orderStatus(v) {
      switch (v) {
        case "1":
          return "待付款";
        case "2":
          return "待发货";
        case "3":
          return "待收货";
        case "4":
          return "已完成";
        case "5":
          return "已取消";
      }
    },
    billType(v) {
      switch (v) {
        case 0:
          return "增值税普通发票";
        case 1:
          return "纸制发票";
        case 2:
          return "增值税专用发票";
        default:
          return "——";
      }
    },
  },
  computed: {
    ...commonIndex.mapState(["userType", "allJurisdiction", "detailsSeach"]),
    ...mapState(["getexportOrder"]),
  },
  async created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      if (this.detailsSeach[router].orderStatus) {
        this.queryParams.statuValue = this.detailsSeach[router].orderStatus;
      }

      // this.cascaderValue=this.detailsSeach[router].cascaderValue
    }
    let data = await this.getbasistime();
    console.log(data.data.month, "1111111111");
    this.orderApi.createTime = data.data.month;
    this.orderApi.createTimeStart =
      this.dateToMs(data.data.month[0]) + " 00:00";
    this.orderApi.createTimeEnd = this.dateToMs(data.data.month[1]) + " 23:59";
    await this.getOrderList();
    await this.getTime();
    await this.getOrderStatistics();
    //前端获取支付方式列表
    this.getPaymentTypeList().then((res) => {
      if (res.code == 200) {
        this.payOption = res.data;
      }
    });
  },
  methods: {
    ...mapActions([
      "postorderlist",
      "getorderdetail",
      "getorderqualification",
      "getbasistime",
      "getQualifyDetail",
      "postgetExportKey",
      "getgetexportOrder",
      "postcancelOrder",
      "getPaymentTypeList",
      "orderStatistics",
    ]),
    ...commonIndex.mapMutations(["changeSeach"]),
    /** 开票信息查看 */
    async oninforma(userId) {
      // let res=await getUserBill({ userId })
      //   this.Billingdata = res.content;
      // ;
    },
    async getOrderStatistics() {
      // this.moneyfrom = {
      //   allamount: 0, //订单总金额
      //   noamount: 0, //订单未支付总金额
      //   refuseAmount: 0,
      //   outboundAmount: 0,
      // };
      Object.assign(this.moneyfrom, this.$options.data().moneyfrom);
      let data = await this.orderStatistics(this.orderApi);
      // if (data.content.length > 0) {
      //   data.content.forEach((v) => {
      //     if (v.orderStatus == 1) {
      //       this.moneyfrom.noamount = v.amount;
      //     }
      //     if (v.orderStatus == 2) {
      //       this.moneyfrom.allamount = v.amount;
      //     }
      //     if (v.orderStatus == 3) {
      //       this.moneyfrom.refuseAmount = v.amount;
      //     }
      //     if (v.orderStatus == 4) {
      //       this.moneyfrom.outboundAmount = v.amount;
      //     }
      //   });
      // }
      if (data.code == "000000") {
        Object.assign(this.moneyfrom, data.content);
      }
    },
    changedate() {},
    // 格式化时间
    timeFormat(date) {
      if (!date || typeof date === "string") {
        this.error("参数异常，请检查...");
      }
      var y = date.getFullYear(); //年
      var m = date.getMonth() + 1; //月
      var d = date.getDate(); //日

      return y + "-" + m + "-" + d;
    },

    //获取这周的周一
    getFirstDayOfWeek() {
      var date = new Date();
      var weekday = date.getDay() || 7; //获取星期几,getDay()返回值是 0（周日） 到 6（周六） 之间的一个整数。0||7为7，即weekday的值为1-7

      date.setDate(date.getDate() - weekday + 1); //往前算（weekday-1）天，年份、月份会自动变化
      date = this.timeFormat(date);
      return date;
    },
    // 获取选择时间
    async getTime() {
      let firstDayOfWeek = this.getFirstDayOfWeek();
      let data = await this.getbasistime();
      if (data.code == 200) {
        this.pickerOptions.shortcuts = [
          {
            text: "今日",
            onClick(picker) {
              picker.$emit("pick", [data.data.today, data.data.today]);
            },
          },
          {
            text: "昨日",
            onClick(picker) {
              picker.$emit("pick", [data.data.yesterday, data.data.yesterday]);
            },
          },
          {
            text: "本周",
            onClick(picker) {
              picker.$emit("pick", [firstDayOfWeek, data.data.today]);
            },
          },
          {
            text: "当月",
            onClick(picker) {
              picker.$emit("pick", data.data.month);
            },
          },
          {
            text: "上月",
            onClick(picker) {
              picker.$emit("pick", data.data.last_month);
            },
          },
        ];
      }
    },
    // 获取订单列表
    async getOrderList() {
      this.loading = true;
      let data = await this.postorderlist(this.orderApi);
      this.loading = false;
      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.orderApi };
      // save.cascaderValue = this.cascaderValue;
      save.router = router;
      this.changeSeach(save);
      data.content.list.forEach((v, i) => {
        let index = (data.content.pageNum - 1) * data.content.pageSize + 1 + i;
        this.$set(v, "index", index);
        // this.$set(v, 'BILL_TYPE_STRING', v.userBill.BILL_TYPE_STRING)
      });
      this.OrderqueryList = data.content;
    },
    // 清楚
    eliminate() {
      this.orderApi = {
        createTime: [],
      };
      this.queryParams.statuValue = "";
      this.toSearch();
    },
    dateToMs(date) {
      let result = new Date(date).getTime();
      var date = new Date(result); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      const Y = date.getFullYear() + "-";
      const M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return Y + M + D;
    },
    // 搜索
    toSearch() {
      this.orderApi.currPage = 1;
      if (this.orderApi.createTime) {
        if (this.orderApi.createTime.length > 0) {
          this.orderApi.createTimeStart =
            this.dateToMs(this.orderApi.createTime[0]) + " 00:00";
          this.orderApi.createTimeEnd =
            this.dateToMs(this.orderApi.createTime[1]) + " 23:59";
        }
      } else {
        this.orderApi.createTimeStart = "";
        this.orderApi.createTimeEnd = "";
      }

      this.getOrderList();
      this.getOrderStatistics();
    },
    // 分页数据
    handleCurrentChange(e) {
      this.orderApi.currPage = e;
      this.getOrderList();
    },
    /** 查询列表数据 */
    getList() {
      if (this.dateRange) {
        this.queryParams.beginTime = this.dateRange[0];
        this.queryParams.endTime = this.dateRange[1];
      } else {
        this.queryParams.beginTime = undefined;
        this.queryParams.endTime = undefined;
      }
    },
    /** 搜索按键操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按键操作 */
    resetQueryList() {
      Object.assign(this.queryParams, this.$options.data().queryParams);
      this.dateRange = [];
      this.getList();
    },
    /** 点击标记 */
    StatusValue(item) {
      this.orderApi.orderStatus = item.id;
      this.queryParams.statuValue = item.id;
      this.orderApi.currPage = 1;
      this.getOrderList();
      this.getOrderStatistics();
    },
    copyed(txt) {
      // 创建输入框元素
      let oInput = document.createElement("input");
      // 将想要复制的值
      oInput.value = txt;
      // 页面底部追加输入框
      document.body.appendChild(oInput);
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      // 弹出复制成功信息
      this.$message.success("复制成功");
      // 复制后移除输入框
      oInput.remove();
      // this.$copyText(txt)
      //   .then(() => {
      //     this.$message.success({ message: "已成功复制到剪切板" });
      //   })
      //   .catch(() => {
      //     this.$message.error({ message: "复制失败" });
      //   });
    },
    /** 多选框选中数据 */
    handleSelectionChange(selection) {
      this.ids = [];
      for (let i in selection) {
        this.ids.push(selection[i]);
      }
      this.batch = !selection.length;
    },
    /** 批量发货弹窗 */
    async dialogShipmentoff() {
      // let boo = verificationTime(
      //   this.orderApi.createTimeStart,
      //   this.orderApi.createTimeEnd
      // );
      // if (!boo) return;
      if (this.orderApi.createTime) {
        if (this.orderApi.createTime.length > 0) {
          this.orderApi.createTimeStart =
            this.dateToMs(this.orderApi.createTime[0]) + " 00:00:00";
          this.orderApi.createTimeEnd =
            this.dateToMs(this.orderApi.createTime[1]) + " 23:59:59";
        }
      }

      let queryParams = this.orderApi;
      downLoadZip(
        `/ptuser/order/exportOrderInfoList`,
        "xlsx",
        "订单",
        queryParams,
        "app",
        "post"
      );

      // if(data.data){
      //   let res = await this.getexportOrder({accesskey: data.data.accesskey});
      //   console.log(res)
      // }

      // let url = this.getexportOrder + "?" + "accesskey=" + data.data.accesskey;
      // window.location.href=url

      // let data = await this.getgetexportOrder({ accesskey: res.data.accesskey })
      // console.log(data)

      // this.ShipmentList = this.ids.map((item) => {
      //   return {
      //     id: item.id,
      //     orderNumber: item.orderNumber, //订单号
      //     logisticscom: "", //物流公司
      //     Logisticsordin: "", //物流单号
      //   };
      // });
      // this.dialogShipment = true;
    },
    AddShipment() {},
    /** 修改发票弹窗 */
    Billinginformation(row) {
      this.getdataed = row;
      this.$refs.popu.dialogInvoice = true;
    },
    /** 详情按键操作 */
    detailsing(row) {
      this.$router.push({
        path: "/detailsedes",
        query: { id: row.id },
      });
    },
    /** 取消订单操作 */
    cancelOrder(row) {
      this.$confirm("确定取消订单？", "取消订单提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        this.postcancelOrder({ orderIds: [row.id] }).then((res) => {
          if (res.code == 200) {
            this.$message.success("取消订单成功");
            this.getOrderList();
            this.getOrderStatistics();
          }
        });
      });
    },
    /** 修改收货人信息弹窗 */
    upconsigen(row) {
      this.getdataed = row;
      this.$refs.popu.dialogConsigne = true;
    },
    /** 发货弹窗 */
    pmentdata(row) {
      this.getdataed = row;
      this.$refs.popu.dialogShipdata = true;
      this.$nextTick((_) => {
        this.$refs.popu.title = "物流信息填写";
      });
    },
    /** 编辑物流信息弹窗 */
    Shipmentdata(row) {
      this.getdataed = row;
      this.$refs.popu.dialogShipdata = true;
      this.$nextTick((_) => {
        this.$refs.popu.title = "编辑物流信息";
      });
    },
    /** 查看证件弹窗 */
    async CheckId(row) {
      sessionStorage.setItem("userId", JSON.stringify(row.userId));
      await this.getorderdetail({ user_id: row.userId, oid: row.groupId });
      let data = await this.getQualifyDetail({ user_id: row.userId });
      data.forEach((item) => {
        this.$set(item, "qualify_data", []);
        if (item.detail) {
          item.detail.forEach((v) => {
            if (v.item_type == 3) {
              item.qualify_data.push(v.img_url);
            }
          });
        }
      });
      data = data.filter((item) => {
        return item.detail;
      });
      this.qualificationData = data;
      this.$refs.seeid.init(row);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-date-editor .el-range-separator {
  padding: 0 5px;
  line-height: 32px;
  width: 20%;
  color: #303133;
}
</style>
<style scoped>
.el-form-item {
  margin-bottom: 5px;
}
.el-input--small .el-input__inner {
  width: 180px;
}
.el-badge__content {
  border-radius: 0px;
  padding: 0 3px;
  color: black;
}

.el-badge__content.is-fixed {
  right: 30px;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.merch-card {
  width: 100%;
  min-width: 1000px;
  max-height: 100%;
  padding: 20px;
  padding-top: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  background: #ffffff;
}
.app-container {
  width: 100%;
  height: 100%;
  // background: red;
}
.dataForm {
  width: 100%;
  // background: rebeccapurple;
}
.fy {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: #515a6e;
}
.sign {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 20px;
  .bulesd {
    margin-left: 10px;
    padding: 0px 10px 0px 10px;
    height: 37px;
    line-height: 37px;
    background: #06b7ae;
    color: #fff;
    font-size: 14px;
    border-radius: 4px;
    .buled {
      color: #000;
      margin: 10px;
      font-size: 10px;
    }
  }
}
.shiped {
  border: 1px dotted #06b7ae;
  border-radius: 3px;
  .shiping {
    margin-left: 30px;
    padding-left: 10px;
    padding-right: 5px;
  }
}

.paging {
  display: flex;
  align-items: center;
  position: absolute;
  right: 15px;
  margin-top: 20px;
}
.pageData {
  font-size: 12px;
  span {
    color: red;
  }
}
.textColor {
  color: blue;
}
.opp {
  color: #06b7ae;
  background: rgba(46, 50, 56, 0.05);
  border-radius: 4px;
}
.rightBtn {
  display: flex;
  color: rgba(28, 31, 35, 0.6);
}
.defaultStyle {
  width: 100px;
  padding: 10px 20px;
  text-align: center;
  line-height: 100%;
  font-size: 14px;
  cursor: pointer;
}
.rejectContent {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.upImg {
  margin-top: 20px;
  margin-left: 80px;
}
.lianle {
  font-size: 12px;
  color: #06b7ae;
}
.Search {
  width: 100%;
  .search-box {
    display: flex;
    flex-wrap: wrap;
  }
  .sch-1 {
    margin-top: 10px;
    width: 18%;
    min-width: 200px;
    display: flex;
    .sch-title {
      width: 40%;
      min-width: 80px;
      height: 100%;
      font-size: 13px;
      border-right: none;
      border-radius: 4px 0px 0px 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 0px 0px 5px !important;
    }
    .clear-elbtn {
      margin-left: 20px;
    }
  }
  .sch-2 {
    margin: 5px 1%;
  }
  .sch-3 {
    width: 38%;
    .sch-title {
      width: 120px;
    }
  }
}
::v-deep .el-select {
  width: 100%;
}
.el-form {
  display: flex;
  flex-wrap: wrap; // 允许流式布局
  gap: 10px 20px; // 给每个输入框加外边距
}
.gree_btn {
  border: 1px solid #06b7ae;
  color: #06b7ae;
}
.imagesing {
  height: 18px;
  margin-top: 10px;
  margin-right: 3px;
}
.rightds {
  display: flex;
  justify-content: right;
  margin-bottom: 10px;
}
.numsize {
  font-size: 16px;
}
</style>
